import { Launch, LogoLarge, Social } from 'components';
import ReactModal from 'react-modal';
import bgGradient from './bg-gradient.jpg';
import bg from './bg-home.jpg';
import './style.css';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

export const HomeScreen = () => {
  return (
    <div className="w-full">
      <div
        className="bg-white bg-cover flex flex-grow items-center justify-center flex-col p-10 min-h-screen w-full"
        style={{ backgroundImage: `url(${bg})`, backgroundPositionY: '65%' }}
      >
        <div className="max-w-full mb-8">
          <LogoLarge fill="#F1F373" />
        </div>
        <div className="mb-4">
          <Social />
        </div>
      </div>
      <div
        className="bg-white bg-center bg-cover flex flex-grow items-center justify-center flex-col px-10 min-h-screen w-full text-white md:px-24 md:p-10"
        style={{ backgroundImage: `url(${bgGradient})` }}
      >
        <Launch />
      </div>
    </div>
  );
};
