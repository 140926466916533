import { Social } from 'components';
import { INFO_PAGES } from 'config/infoPages';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import './style.css';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

type InfoParams = {
  slug: string;
};

export const InfoScreen = () => {
  const { slug } = useParams<InfoParams>();

  const { description, title } = INFO_PAGES[slug];

  return (
    <div className="flex flex-grow items-center justify-center flex-col p-10 bg-white text-black min-h-screen">
      <div className="max-w-4xl pt-24 pb-8">
        <h1 className="text-4xl font-black tracking-tight mb-4 uppercase italic">
          {title}
        </h1>
        <div
          className="italic" // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {slug === 'about' && (
          <div className="mt-8">
            <Social linkStyle="text-black hover:text-yellow-200" />
          </div>
        )}
      </div>
    </div>
  );
};
