/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { LogoLarge, Social } from 'components';
import { CATEGORIES } from 'config/categories';
import { FC } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import './style.css';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

type CategoryParams = {
  slug: string;
};

type Props = { onClickModal: () => void };

export const CategoryScreen: FC<Props> = ({ onClickModal }) => {
  const { slug } = useParams<CategoryParams>();

  const { backgroundColor, description, header, title, products } =
    CATEGORIES[slug];

  return (
    <div className="w-full">
      {header && (
        <div
          className="bg-center bg-white bg-cover flex items-center justify-center w-full transition-colors duration-600 ease-in-out"
          style={{
            backgroundColor,
            backgroundImage: `url(/images/${header})`,
            height: '70vh'
          }}
        >
          <LogoLarge fill={backgroundColor} />
        </div>
      )}
      <div>
        <div
          className="flex items-center justify-center flex-col p-10 py-20 min-h-screen transition-colors duration-600 ease-in-out"
          style={{ backgroundColor }}
        >
          <div className="max-w-5xl">
            <h1
              className="text-4xl font-black tracking-tight mb-4 uppercase italic"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="italic">{description}</p>

            {slug === 'about' && (
              <div className="mt-8">
                <Social linkStyle="text-black hover:text-yellow-200" />
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-20">
              {products?.map(
                ({ name, priceRegular, pricePromo, image, subtitle }, i) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${name}-${i}`}
                    className="cursor-pointer italic text-center mb-4"
                    onClick={onClickModal}
                  >
                    <div className="bg-white w-64 h-96 flex overflow-hidden rounded-xl mb-4 p-4">
                      <div
                        className="bg-center bg-no-repeat bg-contain mx-auto w-full "
                        style={{
                          backgroundImage: `url(/images/${image})`,
                          backgroundSize: 'contain'
                        }}
                      />
                    </div>

                    {/* <img className="" src={`/images/${image}`} alt={name} /> */}

                    <p className="text-lg">{name}</p>
                    {priceRegular && (
                      <div className="line-through">&euro;{priceRegular}</div>
                    )}
                    {pricePromo && (
                      <div className="text-2xl font-black">
                        &euro;{pricePromo}
                      </div>
                    )}
                    {subtitle && <div className="font-black">{subtitle}</div>}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
