import { Layout, ModalContent } from 'components';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import { CategoryScreen, HomeScreen, InfoScreen } from 'screens';
import Modal from 'react-modal';

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
});

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,.8)',
    padding: 0
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    inset: 0
  }
};

const App: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div
      style={{
        flex: 1,
        padding: 16,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <p>Vergeet het, wij zijn fake!</p>
      <p>
        Dit is een fake website die werd opgezet om jongeren te sensibiliseren
        over de gevaren van namaak.
      </p>
      <p>
        Meer weten?{' '}
        <a
          href="https://news.economie.fgov.be/211184-vergeet-het-het-is-fake"
          target="_blank"
        >
          Klik hier
        </a>
      </p>
      <p style={{ marginTop: 16 }}>Oublie, nous sommes fake.</p>
      <p>
        Ceci est un faux site créé pour sensibiliser les jeunes aux dangers de
        la contrefaçon.
      </p>
      <p>
        Tu veux en savoir plus ?{' '}
        <a href="https://news.economie.fgov.be/211675-oublie-c-est-du-fake">
          Cliquez ici
        </a>
      </p>
    </div>
  );
  return (
    <Router>
      <ScrollToTop />
      <Layout openModal={openModal}>
        <Switch>
          <Route exact path="/">
            <HomeScreen />
          </Route>
          <Route exact path="/category/:slug">
            <CategoryScreen onClickModal={openModal} />
          </Route>
          <Route exact path="/info/:slug">
            <InfoScreen />
          </Route>
        </Switch>
        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ModalContent onClose={closeModal} />
        </Modal>
      </Layout>
    </Router>
  );
};

export default App;
